<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="车辆编号" prop="vehicleNo">
                <a-input v-model="queryParam.vehicleNo" placeholder="请输入车辆编号" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="车牌号" prop="licensePlateNum">
                <a-input v-model="queryParam.licensePlateNum" placeholder="请输入车牌号" allow-clear />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="加油人" prop="fillingBy">
                  <a-input v-model="queryParam.fillingBy" placeholder="请输入加油人" allow-clear />
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery" id="tableQueryBtn"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
        <div class="table-operations">
          <a-button
            type="primary"
            @click="$refs.createForm.handleAdd()"
            v-hasPermi="['tenant:iot:vehicleFuelFillingRecord:add']"
          >
            <a-icon type="plus" />新增
          </a-button>
          <a-button
            type="primary"
            :disabled="single"
            @click="$refs.createForm.handleUpdate(undefined, ids)"
            v-hasPermi="['tenant:iot:vehicleFuelFillingRecord:edit']"
          >
            <a-icon type="edit" />修改
          </a-button>
          <a-button
            type="danger"
            :disabled="multiple"
            @click="handleDelete"
            v-hasPermi="['tenant:iot:vehicleFuelFillingRecord:remove']"
          >
            <a-icon type="delete" />删除
          </a-button>
          <a-button type="primary" @click="$refs.importExcel.importExcelHandleOpen()">
            <a-icon type="import" />导入
          </a-button>
          <a-button type="primary" @click="handleExport" v-hasPermi="['tenant:iot:vehicleFuelFillingRecord:export']">
            <a-icon type="download" />导出
          </a-button>
          <!-- <a-button
          type="dashed"
          shape="circle"
          :loading="loading"
          :style="{ float: 'right' }"
          icon="reload"
          @click="getList"
        /> -->
        </div>
      </a-toolbar>
      <!-- 增加修改 -->
      <create-form ref="createForm" :oilTypeOptions="oilTypeOptions" :oilLabelOptions="oilLabelOptions" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        tid="1"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
      >
        <span slot="vehicleNo" slot-scope="text, record">
          {{ record.vehicleNo | record.licensePlateNum }}
        </span>
        <span slot="fillingTime" slot-scope="text, record">
          {{ parseDateTime(record.fillingTime, 'yyyy-MM-dd') }}
        </span>
        <span slot="oilType" slot-scope="text, record">
          {{ oilTypeFormat(record) }}
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="status" slot-scope="text, record">
          {{ statusFormat(record) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['tenant:iot:vehicleFuelFillingRecord:edit']" />
          <a
            @click="$refs.createForm.handleUpdate(record, undefined)"
            v-hasPermi="['tenant:iot:vehicleFuelFillingRecord:edit']"
          >
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['tenant:iot:vehicleFuelFillingRecord:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['tenant:iot:vehicleFuelFillingRecord:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
      <import-excel ref="importExcel" @ok="getList" :sourceForm="this" />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {
  listVehicleFuelFillingRecord,
  delVehicleFuelFillingRecord,
  exportVehicleFuelFillingRecord,
  importTemplate,
  importData
} from '@/api/iot/vehicleFuelFillingRecord'
import CreateForm from './modules/CreateForm'
import ImportExcel from './modules/ImportExcel'
export default {
  name: 'VehicleFuelFillingRecord',
  components: {
    CreateForm,
    ImportExcel
  },
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 所加的油类型 gasoline=汽油、diesel=柴油、gas=燃气字典
      oilTypeOptions: [],
      // 查询参数
      queryParam: {
        vehicleNo: null,
        licensePlateNum: null,
        fillingBy: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '车辆（车牌号|自编号）',
          dataIndex: 'vehicleNo',
          scopedSlots: { customRender: 'vehicleNo' },
          ellipsis: true,
          align: 'center',
          width: 200
        },
        {
          title: '加油金额',
          dataIndex: 'amount',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '新增里程',
          dataIndex: 'increasedMileage',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '加油量',
          dataIndex: 'oilQuantity',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '百公里油耗',
          dataIndex: 'oilConsumption100km',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '加油时车辆里程数',
          dataIndex: 'mileage',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '相似车型百公里油耗',
          dataIndex: 'oilConsumption100kmStandard',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '加油时间',
          dataIndex: 'fillingTime',
          scopedSlots: { customRender: 'fillingTime' },
          ellipsis: true,
          align: 'center',
          width: 200
        },
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      statusOptions: [
        {
          dictLabel: '有效',
          dictValue: '0'
        },
        {
          dictLabel: '无效',
          dictValue: '1'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
    this.getDicts('iot_oil_type').then((response) => {
      this.oilTypeOptions = response.data
    })
    this.getDicts('iot_diesel_type').then((response) => {
      this.oilLabelOptions = response.data
    })
  },
  computed: {},
  watch: {},
  methods: {
    importTemplateEx() {
      return importTemplate()
    },
    importDataEx() {
      return importData()
    },
    /** 查询加油记录列表 */
    getList() {
      this.loading = true
      listVehicleFuelFillingRecord(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    // 所加的油类型 gasoline=汽油、diesel=柴油、gas=燃气字典翻译
    oilTypeFormat(row, column) {
      return this.selectDictLabel(this.oilTypeOptions, row.oilType)
    },
    statusFormat(row, Column) {
      console.log('statusFormat', row.status)
      return this.selectDictLabel(this.statusOptions, row.status)
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        vehicleNo: undefined,
        licensePlateNum: undefined,
        fillingBy: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delVehicleFuelFillingRecord(ids).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          return exportVehicleFuelFillingRecord(that.queryParam).then((response) => {
            that.download(response.msg)
            that.$message.success('导出成功', 3)
          })
        },
        onCancel() {}
      })
    }
  }
}
</script>
